/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Divider, Image, Text, Button, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"MÁMA MALUJE"}>
        <Column className="css-171voas --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37823/41098852017f4889aa5580f0d89aaf93_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"MAMA MALUJE"}>
              </Title>

              <Divider style={{"height":2,"backgroundColor":"var(--white)"}}>
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37823/10e304536d26447abe573432cfe6bee3_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37823/10e304536d26447abe573432cfe6bee3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37823/10e304536d26447abe573432cfe6bee3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37823/10e304536d26447abe573432cfe6bee3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37823/10e304536d26447abe573432cfe6bee3_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"Pouring obrazy"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37823/763bf8711f734249bde405f3024bef93_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37823/763bf8711f734249bde405f3024bef93_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37823/763bf8711f734249bde405f3024bef93_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37823/763bf8711f734249bde405f3024bef93_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37823/763bf8711f734249bde405f3024bef93_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"Abstraktní obrazy"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37823/a3841900818e4780ae479bd3cdcfa057_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":673}} srcSet={"https://cdn.swbpg.com/t/37823/a3841900818e4780ae479bd3cdcfa057_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37823/a3841900818e4780ae479bd3cdcfa057_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37823/a3841900818e4780ae479bd3cdcfa057_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37823/a3841900818e4780ae479bd3cdcfa057_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"Dětské obrázky&nbsp;<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"citat"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1670}}>
              
              <Text className="text-box fs--30 w--400 lh--16" content={"<span style=\"color: var(--black);\">Obrazy malované s otevřeným srdcem a myslí...<br>&nbsp;Nechte se vtáhnout do světa fantazie!</span>"}>
              </Text>

              <Button className="btn-box" url={"https://mamamaluje.cz/fotogalerie"} href={"https://mamamaluje.cz/fotogalerie"} content={"Fotogalerie"}>
              </Button>

              <Text className="text-box" content={"- Markéta Solil"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-1"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37823/8bbf0f997271400dbc96041496b92355_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37823/8bbf0f997271400dbc96041496b92355_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37823/8bbf0f997271400dbc96041496b92355_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37823/8bbf0f997271400dbc96041496b92355_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Obraz dle vašich představ</span><br>"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(255, 255, 255);\">Toužíte po zútulnění vašeho domova, nebo snad jen hledáte originální dárek pro svého blízkého?<br>&nbsp;Tak jsou mé obrazy pro vás jasnou volbou.&nbsp;<br><br>&nbsp;Originální obrazy, které jsou tvořené s láskou k umění.&nbsp; &nbsp;<br><br>Ceník&nbsp;<br>(velikost plátna, náročnost a hodinová taxa)<br>Velký obraz cca 150X100cm..............3500 Kč<br>Střední obraz cca 120x80cm...................2500Kč<br>Malý obraz cca &nbsp;60x60 cm.................1500Kč<br><br>Velikosti obrazů uvedené u cen jsou jen orientační.&nbsp;<br><br>Volné obrazy najdeš na Instagramu!&nbsp;<br><br><br><br><br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-gb1bfg pb--30 pt--30" name={"informace-2"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37823/0d7d5ca3d0794e9da9967bf6781b1ea6_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"O mě"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Ahoj! Jmenuji se Markéta. Bydlím v Holicích. Můj život se vždy točil kolem umění. Na základní škole jsem se zajímala o keramiku a chodila do ZUŠ na kurz kreslení. Na střední škole jsem tvořila s dětmi ve školce a následně na vysoké škole a v zaměstnaní, jsem pomocí arteterapie pomáhala lidem najít lásku k umění. Nyní jako maminka na mateřské si plním svůj sen a maluji.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37823/764ce2caa1404ef4b95228e93fadd206_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37823/764ce2caa1404ef4b95228e93fadd206_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37823/764ce2caa1404ef4b95228e93fadd206_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37823/764ce2caa1404ef4b95228e93fadd206_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Markéta Solil"}>
              </Title>

              <Text className="text-box" content={"+420 731 826 182<br>IG: mama_maluje1<br>marketa.solil@seznam.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gi6auyyi7pk"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontaktujte mě&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":740}} content={"<span style=\"color: var(--black);\">Vyplňte prosím své údaje a popište vaši objednávku.<br>Děkuji</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj email"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420 "},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}